import https from "./https"

const file = {
  upload(file, file_name = null) {
    let data = new FormData();
    data.append("file", file);
    if (file_name) {
      data.append("file_name", file_name);
    }

    return https.post('general/files/upload', data)
  },
}

export default file
